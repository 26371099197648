<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Signature - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="true"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="true"
                />
            </v-col>
        </v-row>

        <v-row>

            <v-col
                cols="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Pool Plan
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <span style="font-size: 13px;"><strong>Step 1:</strong> First you must download the pool plan and sign, after you must to send it to the customer for sign</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                class="mx-2"
                                                v-on="on"
                                                @click="downloadFile(URL_ATTACHMENT, poolPlanFiles[0].id, poolPlanFiles[0].fileType, poolPlanFiles[0].fileName)"
                                            >
                                                <v-icon left>mdi-file-download-outline</v-icon>
                                                Download PDF Pool Plan
                                            </v-btn>
                                        </template>
                                        <span>Download PDF</span>
                                    </v-tooltip>

                                </v-col>
                                <v-col
                                    style="text-align: center;"
                                    cols="12"
                                    lg="2"
                                    md="12"
                                    sm="12"
                                >
                                
                                    <v-divider vertical></v-divider>

                                </v-col>

                                <v-col
                                    cols="12"
                                    lg="5"
                                    md="12"
                                    sm="12"
                                >
                                    <span style="font-size: 13px;"><strong>Step 2:</strong> Upload the pool plan signed to complete this process and go to the final plan step</span>
                                    
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                style="width: 100% !important; margin-top: 2rem; margin-left: 0px !important;"
                                                class="mx-2"
                                                v-on="on"
                                                @click="uploadSignedPoolPlan()"
                                            >
                                                <v-icon left>mdi-file-upload-outline</v-icon>
                                                Upload Signed Pool Plan
                                            </v-btn>
                                        </template>
                                        <span>Upload Signed Document</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <div 
            v-if="serviceProjectResponse.status > 7"
            class="text-center"
        >
            
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="cancel()"
                            >
                                Back
                            </v-btn>
                        </template>
                        <span>Back</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>


        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <UploadFile 
            v-on:methodConfirmAfterUpload="dialogUploadFile.methodAfterUpload"
            :showUploadFile.sync="dialogUploadFile.show"
            :idKey="dialogUploadFile.idKey"
            :reload="dialogUploadFile.reload"
            :acceptType="dialogUploadFile.acceptType"
            :typeAttachment="dialogUploadFile.typeAttachment"
            :typeDocument="dialogUploadFile.typeDocument"
            :multipleFiles="dialogUploadFile.multipleFiles"
            :showMsgConfirmation="dialogUploadFile.showMsgConfirmation"
            :msgConfirmation="dialogUploadFile.msgConfirmation"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import UploadFile from "@/components/File/UploadFile";
    import JobInformation from "@/components/Services/JobInformation";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, URL_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            UploadFile,
            JobInformation,
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

            loading: false,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            serviceDraftingUploadFilesRequest: {
                id: 0,
                idService: 0,
                poolPlanNotes: "",
            },

            poolPlanListFiles: [],
            poolPlanFiles: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogUploadFile: {
                show: false,
                idKey: 0,
                reload: 0,
                acceptType: ".pdf",
                typeAttachment: 0,
                typeDocument: 0,
                multipleFiles: false,
                showMsgConfirmation: true,
                msgConfirmation: "",
                methodAfterUpload: ( () => {} ),
            },

        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    // let response = await this.$store.dispatch("serviceModule/GetById", this.id);
                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }
                    
                    let responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.id);

                    if (responseDraftingUploadFiles.result.id != 0) {
                        this.serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;

                        this.poolPlanListFiles = [];
                        this.poolPlanFiles = [];

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: this.serviceDraftingUploadFilesRequest.id }); 

                        let poolPlanListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value);
                        if (poolPlanListFiles != null && poolPlanListFiles != undefined) {

                            poolPlanListFiles.forEach(itemFile => {

                                this.poolPlanFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/drafting/pipeline" });
            },

            uploadSignedPoolPlan() {

                this.dialogUploadFile = {
                    show: true,
                    idKey: this.serviceDraftingUploadFilesRequest.id,
                    reload: Math.random(),
                    acceptType: ".pdf",
                    typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                    typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN_SIGNED.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "You are about to upload the signed pool plan, and go to FINAL PLAN step, confirm your decision?",
                    methodAfterUpload: this.uploadPoolPlan,
                };
            },

            async uploadPoolPlan() {
                
                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: 11 //Final Plan
                };

                let result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Pool Plan uploaded successfully");
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.cancel();

                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>